import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, mergeMap, share, tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../../../environments/environment';

interface RequestBody {
  refresh_token: string;
}

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  intercept(req: HttpRequest<RequestBody>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.auth.getAuthToken();

    const goNext = (): Observable<HttpEvent<unknown>> => {
      if (req.url.includes('/users/logout')) {
        return next.handle(req);
      }

      const authHeader = this.auth.getAuthHeader();
      let authReq = req;
      // Clone the request to add the new header.
      if (authHeader && !req.body?.refresh_token) {
        authReq = req.clone({ headers: req.headers.set('Authorization', authHeader) });
      }

      // Pass on the cloned request instead of the original request.
      return next.handle(authReq).pipe(
        catchError((error: unknown) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              this.auth.logout();
            }
          }
          return throwError(error);
        }),
      );
    };

    if (
      req.url.includes('/users/logout') ||
      req.body?.refresh_token ||
      !req.url.includes(environment.api_url) ||
      !this.auth.isTokenExpiresIn(token, 120)
    ) {
      return goNext();
    }

    if (!this.auth.refreshTokenProcess) {
      // eslint-disable-next-line functional/immutable-data
      this.auth.refreshTokenProcess = this.auth.refreshAccessToken().pipe(
        tap(authToken => {
          this.auth.setAuthToken(authToken);
        }),
        catchError(err => {
          this.auth.logout();
          return throwError(err);
        }),
        finalize(() => {
          // eslint-disable-next-line functional/immutable-data
          this.auth.refreshTokenProcess = null;
        }),
        share(),
      );
    }
    return this.auth.refreshTokenProcess.pipe(
      mergeMap(() => {
        return goNext();
      }),
    );
  }
}
