import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FileDropDirective } from './directives/file-drop/file-drop.directive';

const DECLARATIONS = [FileDropDirective];

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [...DECLARATIONS],
  exports: [...DECLARATIONS],
})
export class SharedModule {}
