import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { AuthLibService } from '../../services/auth/auth-lib.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  isLoggedIn$: Observable<boolean> = this.authService.loginStateChange$;

  constructor(private authService: AuthService, private authLibService: AuthLibService) {}

  show(): void {
    this.authLibService.showAuthModal();
  }

  logout(): void {
    this.authService.logout();
  }
}
