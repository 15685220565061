import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthToken } from '../../classes/auth-token';
import { User } from '../../interface';
import { UserService } from '../user.service';
import { filterEmpty } from '../../../rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authTokenKey = 'access_token';
  authToken: AuthToken | null;
  loginStateChange$: Observable<boolean>;
  refreshTokenProcess: Observable<AuthToken> | null = null;

  private loginStateChange: BehaviorSubject<boolean>;
  private user = new BehaviorSubject<User | null>(null);

  user$ = this.user.asObservable();
  authorizedUser$ = this.user$.pipe(filterEmpty());
  userName$ = this.user$.pipe(map(user => this.getFirstName(user)));

  constructor(private router: Router, private userService: UserService) {
    this.authToken = this.getAuthToken();
    this.loginStateChange = new BehaviorSubject<boolean>(!this.isTokenExpiresIn(this.getAuthToken()));
    this.loginStateChange$ = this.loginStateChange.asObservable();
  }

  isLoggedIn(): boolean {
    return !!this.getAccessToken();
  }

  logout(): void {
    this.userService.logout().subscribe(() => {
      if (this.authToken) {
        sessionStorage.removeItem(this.authTokenKey);
        this.authToken = null;
        this.loginStateChange.next(false);
      }
    });
    const redirectUrl = this.router.url.startsWith('/login') ? '/apps' : this.router.url;
    this.router.navigate(['/login'], {
      queryParams: {
        redirectUrl: encodeURI(redirectUrl),
      },
    });
  }

  setUser(user: User): void {
    this.user.next(user);
  }

  setAuthToken(value: AuthToken): void {
    const oldAuthToken = this.getAuthToken() || {};
    const mergedAuthToken = {
      ...oldAuthToken,
      ...value,
    };
    sessionStorage.setItem(this.authTokenKey, JSON.stringify(mergedAuthToken));
    this.authToken = mergedAuthToken;
    this.loginStateChange.next(true);
  }

  isTokenExpiresIn(token: AuthToken | null, seconds = 0): boolean {
    if (!token || !token.accessToken || !token.accessToken.length) {
      return true;
    }
    return new Date(token.expiresAt).getTime() - seconds * 1000 <= new Date().getTime();
  }

  getAuthToken(): AuthToken | null {
    const authTokenJson = sessionStorage.getItem(this.authTokenKey);
    return authTokenJson ? JSON.parse(authTokenJson) : null;
  }

  getAccessToken(): string {
    return this.authToken ? this.authToken.accessToken : '';
  }

  getAuthHeader(): string {
    return this.authToken ? 'Bearer ' + this.authToken.accessToken : '';
  }

  getUser(): User | null {
    return this.user.value;
  }

  getUserEmail(): string | null {
    return this.user.value?.email ?? null;
  }

  getFirstName(user = this.user.value): string {
    if (!user || !user.firstName) {
      return 'Stranger';
    }
    return user.firstName;
  }

  getCustomerId(): string | null {
    return this.user.value ? this.user.value.stripeCustomerId : null;
  }

  getSubscriptionId(): string | null {
    return this.user.value ? this.user.value.stripeSubscriptionId : null;
  }

  refreshAccessToken(): Observable<AuthToken> {
    return this.userService.refreshAccessToken();
  }
}
