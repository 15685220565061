import { ChangeDetectionStrategy, Component } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AuthLibService } from '../../services/auth/auth-lib.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
  siteUrl = environment.site_url;

  constructor(private authLibService: AuthLibService) {}

  show(): void {
    this.authLibService.showAuthModal();
  }
}
