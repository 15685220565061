import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { AuthLibService } from './core/services/auth/auth-lib.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  options = this.authLibService.options;

  constructor(private authLibService: AuthLibService) {}

  ngOnInit(): void {
    this.authLibService.start();
  }

  ngOnDestroy(): void {
    this.authLibService.stop();
  }
}
