import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as sentry from '@sentry/angular-ivy';

sentry.init({
  dsn: environment.sentry.dsn,
  enabled: environment.sentry.enabled,
  tracePropagationTargets: [environment.site_url],
  integrations: [
    new sentry.BrowserTracing({
      routingInstrumentation: sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
