// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const BASE_DOMAIN = '0account.com';

export const environment = {
  production: false,
  environment: 'staging',
  appId: 'd0f78877-105c-467a-963e-f987b6e16c16',
  // appId: '4c5705ba-c932-4714-8565-3f1ba7492252', // staging
  ws_url: `ws://staging.${BASE_DOMAIN}/ws`,
  site_url: `https://${BASE_DOMAIN}`,
  api_url: `https://staging.v1.${BASE_DOMAIN}`,
  docs_url: `https://docs.${BASE_DOMAIN}`,
  portal_url: `https://my.${BASE_DOMAIN}`,
  stripe_publishable_key:
    'pk_test_51H6j93LCNsiTHKzWgSQ3rHwtfRLAolTScoVdH2dmZX5NVKkby6Wzmgp15W3UeUy8WjL3in95mTgPTB8Mllfzw5Wp00OZD0x39f',
  sentry: {
    enabled: true,
    dsn: 'https://263af81fec37404e8fe79ce6c3cfc868@o942961.ingest.sentry.io/4504463661072384',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
