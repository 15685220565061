import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthToken } from '../classes/auth-token';
import { Profile, User } from '../interface';
import { map } from 'rxjs/operators';

interface RefreshTokenResponse {
  id: string;
  userId: string;
  profileId: string;
  value: string;
  type?: string;
  expiresAt: number;
}

const defaultUsersLimit = 100;
const defaultEmployeesLimit = 1;
const defaultPlan = 'free';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private httpClient: HttpClient) {}

  getUser(): Observable<User> {
    return this.httpClient
      .get<Profile>(environment.api_url + '/users/profiles/me')
      .pipe(map(profile => this.convertProfileToUser(profile)));
  }

  refreshAccessToken(): Observable<AuthToken> {
    return this.httpClient
      .post<RefreshTokenResponse>(
        environment.api_url + '/tokens/refresh',
        {
          refresh_token: true,
        },
        {
          withCredentials: true,
        },
      )
      .pipe(
        map(token => {
          return {
            accessToken: token.value,
            expiresAt: token.expiresAt,
          };
        }),
      );
  }

  logout(): Observable<void> {
    return this.httpClient.get<void>(environment.api_url + '/users/logout', {
      withCredentials: true,
    });
  }

  private convertProfileToUser(profile: Profile): User {
    const organization = profile.organizations?.length ? profile.organizations[0] : null;
    return {
      rawProfile: profile,
      rawOrganization: organization,
      id: profile.id ?? null,
      organizationId: organization?.id?.length ? organization.id : null,
      organizationName: organization?.name?.length ? organization.name : null,
      firstName: profile.firstName ?? '',
      lastName: profile.lastName ?? '',
      profileImage: profile.profileImage ?? null,
      email:
        profile.emails?.find(email => email.isPrimary)?.value ??
        (profile.emails?.length ? profile.emails[0].value : null),
      stripeCustomerId: organization?.stripeCustomerID?.length ? organization.stripeCustomerID : null,
      stripeSubscriptionId: organization?.stripePriceID?.length ? organization.stripePriceID : null,
      currentPlan: organization?.currentPlan?.length ? organization.currentPlan : defaultPlan,
      currentPlanUsersLimit: this.getPositiveNumber(organization?.currentPlanUsersLimit, defaultUsersLimit),
      currentPlanEmployeesLimit: this.getPositiveNumber(organization?.currentPlanEmployeesLimit, defaultEmployeesLimit),
    };
  }

  private getPositiveNumber(value: number | null | undefined, defaultValue: number): number {
    if (!value) {
      return defaultValue;
    }

    return value;
  }
}
