import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from './auth.service';
import '@oila/0account/dist/zero-account/zero-account.esm.js';

@Injectable({
  providedIn: 'root',
})
export class AuthLibService implements OnDestroy {
  options = {
    environment: environment.environment,
    appId: environment.appId,
    wsURL: environment.ws_url,
    callbackURL: environment.api_url + '/users/zero',
    enableWithCredentials: true,
  };

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(public authService: AuthService, private router: Router, private ngZone: NgZone) {}

  start(): void {
    this.init();
  }

  stop(): void {}

  init(): void {
    fromEvent<CustomEvent>(document, '0account-authenticated').subscribe(() => {
      this.onAuth();
    });

    fromEvent<CustomEvent>(document, '0account-logout').subscribe(() => {
      this.onLogout();
    });
  }

  showAuthModal(): void {
    const event = new CustomEvent('0account-toggle-modal');
    document.dispatchEvent(event);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  onAuth(): void {
    this.ngZone.run(() => {
      const queryParams = new URLSearchParams(window.location.search);
      const redirectUrl = queryParams.get('redirectUrl');
      if (redirectUrl) {
        window.location.assign(redirectUrl);
      } else {
        this.goToCabinet();
      }
    });
  }

  onLogout(): void {
    this.authService.logout();
  }

  goToCabinet(): void {
    this.router.navigate(['/apps']);
  }
}
