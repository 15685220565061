import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appFileDrop]',
})
export class FileDropDirective {
  @Input() accept: string;

  @Output() dropped: EventEmitter<File[]> = new EventEmitter<File[]>();

  @HostBinding('class.active') active = false;

  @HostListener('dragover', ['$event']) onDragOver(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.active = true;
  }

  @HostListener('dragleave', ['$event']) onDragLeave(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.active = false;
  }

  @HostListener('drop', ['$event']) onDrop(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();

    let files = Array.from(evt.dataTransfer?.files || ([] as File[]));
    const acceptedTypes: string[] | null = this.accept ? this.accept.split(',') : null;

    files = acceptedTypes ? files.filter((file: File) => acceptedTypes.includes(file.type)) : files;

    if (files.length > 0) {
      this.dropped.emit(files);
    }

    this.active = false;
  }
}
